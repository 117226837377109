import React from 'react';
import './Home.css';
import {TextField, Button, Stack, Box, Divider, Paper} from '@mui/material';
import {styled} from '@mui/material/styles';

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            referrerParams: '',
            charCount: 0
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleHttpsGooglePlayVMIClick = () => {
        const url = 'https://play.google.com/store/apps/details?id=com.abbyy.vantage.mobileinput&launch=true'
        window.open(url, '_blank');
    }
    handleMarketVMIClick = () => {
        const url = 'market://launch?id=com.abbyy.vantage.mobileinput'
        window.open(url, '_blank');
    }

    handleChange(event) {
        this.setState({
            referrerParams: event.target.value,
            charCount: event.target.value.length
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const url = 'market://launch?id=com.abbyy.vantage.mobile.input&referrer=' + this.state.referrerParams
        window.open(url, "_blank");
    }

    handleGooglePlaySampleClick = () => {
        const url = 'https://play.google.com/store/apps/details?id=com.abbyy.vantage.mobile.input&launch=true&referrer=exampleCampaignId'
        window.open(url, '_blank');
    }

    handleMarketSampleClick = () => {
        const url = 'market://launch?id=com.abbyy.vantage.mobile.input'
        window.open(url, '_blank');
    }

    handleIntentSampleClick = () => {
        const url = 'intent://23alot.github.io?foo=bar#Intent;scheme=https;action=android.intent.action.VIEW;end'
        window.open(url, '_blank');
    }

    handleCustomSampleClick = () => {
        const url = 'https://23alot.github.io'
        window.open(url, '_blank');
    }
    handleFirebaseSampleClick = () => {
        const url = 'https://oldvmi.page.link/amTC'
        window.open(url, '_blank');
    }

    render() {
        const userAgent = navigator.userAgent;

        const ButtonWithLabel = ({onClick, name, url}) => {
            return (
                <Box mt={2}>
                    <Button
                        onClick={onClick}
                        variant="outlined"
                        label={url}
                        size="small"> {name} </Button>
                    <p className="button-subtitle">{url}</p>
                </Box>
            );
        }

        return (
            <div>
                <div className="home-container">
                    <div className="buttons-container">

                        <ButtonWithLabel
                            onClick={this.handleHttpsGooglePlayVMIClick}
                            name="Open VMI (production link)"
                            url="https://play.google.com/store/apps/details?id=com.abbyy.vantage.mobileinput&launch=true"
                        />

                        <ButtonWithLabel
                            onClick={this.handleMarketVMIClick}
                            name="Open VMI (market:// link)"
                            url="market://launch?id=com.abbyy.vantage.mobileinput"
                        />

                        <p/>

                        <ButtonWithLabel
                            onClick={this.handleMarketSampleClick}
                            name="Open sample (market:// link)"
                            url="market://launch?id=com.abbyy.vantage.mobile.input"
                        />

                        <form onSubmit={this.handleSubmit} style={{marginTop: 10}}>
                            <Button className="input_button" type="submit" variant="outlined" style={{height: '36px'}}>
                                Open sample (market://launch&referrer=)
                            </Button>
                            <label>
                                <TextField
                                    value={this.state.referrerParams}
                                    onChange={this.handleChange}
                                    placeholder="referrer_params"
                                    style={{marginBottom: '10px'}}
                                    InputProps={{style: {height: 36}}}
                                />
                            </label>
                            <div className="count">count: <strong>{this.state.charCount}</strong></div>
                        </form>

                        <ButtonWithLabel
                            onClick={this.handleGooglePlaySampleClick}
                            name="Open sample (https://, referrer)"
                            url="https://play.google.com/store/apps/details?id=com.abbyy.vantage.mobile.input&launch=true&referrer=exampleCampaignId"
                        />

                        <ButtonWithLabel
                            onClick={this.handleIntentSampleClick}
                            name="Open sample (Intent launch)"
                            url="intent://23alot.github.io?foo=bar#Intent;scheme=https;action=android.intent.action.VIEW;end"
                        />

                        <ButtonWithLabel
                            onClick={this.handleCustomSampleClick}
                            name="Open sample (https://, custom url launch)"
                            url="https://23alot.github.io"
                        />

                        <ButtonWithLabel
                            onClick={this.handleFirebaseSampleClick}
                            name="Open sample (Firebase Dynamic link url)"
                            url="https://oldvmi.page.link/amTC"
                        />
                    </div>
                </div>
                <div className="footer">
                    <p>User Agent: {userAgent}</p>
                </div>
            </div>
        );
    }
}

export default Home;