import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from './Home';
import Privacy from './Privacy';
import Capture from "./Capture";

class App extends React.Component {
    render() {
        return (
            <div className="App">
                <Router>
                    <Routes>
                        <Route path="capture" element={<Capture />} />
                        <Route path="privacy" element={<Privacy/>}/>
                        <Route path="maven" component={() => { 
                            window.location.href = '/usr/local/data/instant_sample_web/public/maven/static/index.html'; 
                            return null;
                        }}/>
                        <Route path="/" element={<Home />} />
                    </Routes>
                </Router>
            </div>
        );
    }
}

export default App;