import React, {useRef, useEffect} from 'react';
import './WebCapture.css';

function WebCapture({setPhoto}) {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const startVideo = () => {
        navigator.mediaDevices
            .getUserMedia({
                video: {
                    facingMode: 'environment',
                    width: {ideal: Infinity},
                    height: {ideal: Infinity},
                }
            })
            .then(stream => {
                let video = videoRef.current;
                video.srcObject = stream;
                return video.play(); // Это вернет промис
            })
            .catch(err => {
                console.error("error:", err);
            });
    };

    useEffect(() => {
        startVideo();
    }, []);

    const capturePhoto = () => {
        let canvas = canvasRef.current;
        let video = videoRef.current;

        // Set canvas dimensions same as video dimensions
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        let context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        setPhoto(canvas.toDataURL('image/png'));
    };

    return (
        <div>
            <video ref={videoRef} width="100%" height="100%" autoPlay></video>
            <canvas ref={canvasRef} style={{display: 'none'}}></canvas> {/* Hiding the canvas element */}
            <div className="buttonContainer">
                <button onClick={capturePhoto}>Сделать снимок</button>
            </div>
        </div>
    );
}

export default WebCapture;
