import React from 'react';
import './Review.css';

function Review({ photo }) {
    return (
        <div>
            <img className="photoDisplay" src={photo} alt="Снимок с веб-камеры" />
        </div>
    );
}

export default Review;