import React, { useState } from 'react';
import WebCapture from './WebCapture';
import Review from './Review';

function Capture() {
    const [photo, setPhoto] = useState('');

    return (
        <div>
            {photo
                ? <Review photo={photo} />
                : <WebCapture setPhoto={setPhoto} />
            }
        </div>
    );
}

export default Capture;





